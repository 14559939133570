import React, { useState } from 'react';
import { InView, useInView } from 'react-intersection-observer';

import Animation from '../animation';
import IndicatorBar from './indicatorBar';
import StepDetails from './stepDetails';

const ids = ['build', 'observe', 'protect'];

/**
 * Component to render sticky indicator bar and each build GetAI system steps.
 * @note This component is executed in the browser (CSR) to avoid SSR issues
 */
const StickyBar = ({ meetSections }) => {
  const [visibleSection, setVisibleSection] = useState(ids[0]);

  const { ref } = useInView({
    threshold: 0.2,
  });

  const setInView = (inView, entry) => {
    if (inView) {
      setVisibleSection(entry.target.getAttribute('id'));
    }
  };

  return (
    <>
      <IndicatorBar isVisibleSection={visibleSection} />
      <div id="build-steps" ref={ref}>
        {meetSections.map((stepData, index) => (
          <InView onChange={setInView} threshold={0.8} key={stepData.title}>
            {({ ref }) => {
              return (
                <div
                  id={ids[index]}
                  ref={ref}
                  className={`flex flex-wrap items-center justify-between gap-12 pb-12 ${
                    index !== meetSections.length - 1 ? 'lg:pb-[220px]' : ''
                  }`}
                >
                  <div ref={ref} className="w-full lg:w-5/12">
                    <StepDetails data={stepData} />
                  </div>
                  <div className={`clear-svg w-full md:w-6/12 lg:shrink`}>
                    <Animation data={stepData} showPlaceholder={false} />
                  </div>
                </div>
              );
            }}
          </InView>
        ))}
      </div>
    </>
  );
};

export default StickyBar;
