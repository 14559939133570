import React from 'react';
import { PortableText } from '@portabletext/react';

import Container from '@/components/common/v5/container';
import GradientCtaLink from '@/components/common/v5/gradient-cta-link';
import { titlePortableText } from '@/components/homepage/v5/hero';

const BuiltFor = ({ data }) => (
  <div className="bg-white md:bg-build-for md:bg-buildFor-position md:bg-no-repeat">
    <Container className="flex flex-col items-start gap-12 py-20 text-center md:flex-row md:justify-between md:gap-32 md:py-32">
      <div className="w-full text-left sm:w-[75%] md:w-[35.5%]">
        <h3 className="text-3xl font-semibold tracking-tight md:text-4xl lg:text-[2.75rem] lg:leading-[3.03125rem]">
          <PortableText
            value={data._rawTitle}
            components={titlePortableText as any}
          />
        </h3>
        <div className="mt-6 w-fit max-md:hidden">
          {data.ctalinks.map(({ _type, label, slug }) => (
            <GradientCtaLink
              url={slug.current}
              label={label}
              isExternal={_type === 'externalLink'}
            />
          ))}
        </div>
      </div>

      <div className="flex flex-col items-start justify-center gap-10 md:gap-20">
        {data.builtForItems.map(({ title, image, description }, idx) => (
          <div
            className="flex max-w-sm flex-col items-start gap-4 text-left"
            key={`built-for-${idx}`}
          >
            <div className="flex size-6 items-center justify-center">
              <img src={image.asset.url} alt={title} />
            </div>
            <p className="text-base font-semibold text-smoky-black md:text-xl lg:text-2xl">
              {title}
            </p>
            <p className="text-smoky-black/80">{description}</p>
          </div>
        ))}
      </div>

      <div className="w-fit md:hidden">
        {data.ctalinks.map(({ _type, label, slug }) => (
          <GradientCtaLink
            url={slug.current}
            label={label}
            isExternal={_type === 'externalLink'}
          />
        ))}
      </div>
    </Container>
  </div>
);

export default BuiltFor;
