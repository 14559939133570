import React from 'react';

import AccordionItem from './accordion-item';

const Accordion = ({ data }) => {
  return (
    <div className="border-t border-[#6a6a6a]/20">
      {data.map((item, idx) => (
        <AccordionItem
          key={idx}
          title={item.question}
          content={item._rawAnswer}
        />
      ))}
    </div>
  );
};

export default Accordion;
