import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { PortableText } from '@portabletext/react';
import { Link } from 'gatsby';

import { trackEvent } from '@/utils/amplitude-tracking';
import ArrowRight from '@/assets/yellow-arrow-right.svg';
import Container from '@/components/common/v5/container';

const userData = {
  name: 'Aman Tyagi',
  position: 'Sr. AI/NLP Research Data Scientist',
  message:
    '“As our systems and methods advanced beyond simple prompts and RAG, Galileo’s end-to-end solution and agile culture became the obvious choice.”',
};

const metrics = {
  title: 'The first evaluation solution built for the needs of the enterprise.',
  data: [
    {
      heading: 'Highly Accurate',
      percentage: '85%',
      description:
        'Evaluation metrics that are proven to reach near-human levels of accuracy.',
    },
    {
      heading: 'Low-Cost',
      percentage: 'Near $0',
      description:
        'Don’t break your budget. Evaluate and monitor systems without relying on expensive API calls.',
    },
    {
      heading: 'Low-Latency',
      percentage: 'millisecond',
      description:
        'Evaluate and monitor GenAI systems without sacrificing performance or end-user experience.',
    },
  ],
};

const graphPoints = [
  { label: 'Galileo Luna', color: 'bg-[#435D8E]' },
  { label: 'RAGAS faithfulness', color: 'bg-[#91752C]' },
  { label: 'Trulens Groundedness', color: 'bg-[#3F6E49]' },
  { label: 'GPT-3.5', color: 'bg-[#935228]' },
];

const UserDetails = () => (
  <div className="flex flex-wrap items-start justify-between gap-6 border-t border-white/20 bg-[#1e1e1e] px-5 py-12 md:items-center md:rounded-b-lg md:px-8 md:py-6">
    <div className="flex flex-col gap-6 md:flex-row md:items-center md:gap-8">
      <StaticImage
        alt={userData.name}
        src="../../../assets/amantyagi.png"
        className="size-16"
        imgClassName="w-auto h-full"
      />
      <div>
        <h6 className="mb-2 text-xl font-semibold">{userData.name}</h6>
        <p className="text-base tracking-tight">{userData.position}</p>
      </div>
    </div>
    <StaticImage
      alt={userData.name}
      src="../../../assets/p&g.svg"
      className="h-8"
      imgClassName="w-auto h-full"
    />
    <p className="text-base text-white/80 md:max-w-[50%]">{userData.message}</p>
  </div>
);

// TODO: Remove dummy data and add query
const Metrics = () => (
  <div className="relative overflow-hidden border-t border-white/20 bg-[#1e1e1e] px-5 py-12 md:px-8 md:py-[3.75rem]">
    <div className="absolute left-[35%] top-[80%] h-[53rem] w-[41rem] bg-gradient-hero blur-[6.5rem] md:left-[25%] md:top-[100%]" />

    <h5 className="z-10 border-b border-white/20 pb-8 text-xl font-medium md:pb-[3.125rem] md:text-[2rem] md:leading-10">
      {metrics.title}
    </h5>
    <div className="flex flex-col gap-10 pt-8 md:flex-row md:flex-wrap md:pt-[3.125rem]">
      {metrics.data.map(({ heading, percentage, description }) => (
        <div className="z-10 flex flex-1 flex-col gap-3 md:gap-8">
          <p className="bg-gradient-text bg-clip-text text-xl font-medium text-transparent">
            {heading}
          </p>
          <p className="leading-[2.1rem]] text-[2rem] font-medium text-white/80 md:text-[3.5rem] md:leading-[3.675rem]">
            {percentage}
          </p>
          <p className="text-white/80% flex-1 text-base">{description}</p>
        </div>
      ))}
    </div>
  </div>
);

const Research = ({ homepageResearchSection }) => (
  <div className="my-14 md:bg-research md:bg-center md:bg-no-repeat">
    <Container className="py-[4.5rem] text-white max-md:p-0 lg:py-32">
      <div className="border border-white/20 bg-smoky-black max-md:border-t-0 md:rounded-lg">
        <div className="flex flex-col md:flex-row">
          <div className="relative flex w-full flex-col justify-between gap-3 overflow-hidden border-b border-white/20 px-5 py-12 md:w-[40%] md:border-r md:px-8 md:py-[3.125rem]">
            <div className="absolute -right-[35%] top-[80%] h-[40rem] w-[18.75rem] -rotate-180 bg-gradient-hero blur-[9rem] md:-right-[10%]" />
            {/* text-[2.125rem]  */}
            <div className="z-10 flex flex-col-reverse gap-3">
              <h4 className="text-[2rem] font-semibold leading-10 -tracking-tight md:text-[2.3rem] md:leading-[2.8rem] lg:text-[2.65rem] lg:leading-[3.3rem]">
                {/* {homepageResearchSection._rawTitle} */}
                <PortableText value={homepageResearchSection._rawTitle} />
              </h4>
              <h6 className="bg-gradient-text bg-clip-text text-base font-medium text-transparent md:text-xl">
                {homepageResearchSection.topTitle}
              </h6>
            </div>
            <div className="z-10 flex flex-col gap-8">
              <p className="text-base font-semibold md:text-xl"></p>
              <div>
                <div className="my-4 first:text-xl">
                  <PortableText
                    value={homepageResearchSection._rawTextAboveLink}
                  />
                </div>
                <Link
                  onClick={() =>
                    trackEvent(homepageResearchSection.researchPageLink)
                  }
                  to={`/${homepageResearchSection.researchPageLink.slug.current}`}
                  className="flex items-center gap-3 text-base font-medium text-orangey-yellow hover:opacity-80"
                >
                  {homepageResearchSection.researchPageLink.label}
                  <ArrowRight />
                </Link>
              </div>
              {/* <Link
                to="/research"
                className="flex items-center gap-3 text-base font-medium text-orangey-yellow hover:opacity-80"
              >
                Explore Our Research
                <ArrowRight />
              </Link> */}
            </div>
          </div>
          <div className="flex w-full flex-col gap-10 px-5 py-12 md:w-[60%] md:px-8 md:py-[3.125rem]">
            <div>
              <h5 className="mb-5 text-sm font-semibold text-white/80 md:text-2xl">
                {homepageResearchSection.graphTitle}
              </h5>
              <ul className="flex flex-wrap items-center gap-3 md:gap-4">
                {graphPoints.map(({ label, color }) => (
                  <li className="flex items-center gap-1">
                    <span className={`h-3 w-3 rounded-[50%] ${color}`} />
                    <p className="text-xs font-medium leading-[0.875rem]">
                      {label}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="max-h-[25rem]">
              <StaticImage
                alt="Research graph"
                src="../../../assets/graph-v2.svg"
                imgClassName="object-cover"
              />
            </div>
            <div className="z-10 flex flex-col gap-1.5">
              <h6 className="text-xl font-medium md:text-[2rem] md:leading-10">
                {homepageResearchSection.graphSubtitle}
              </h6>
              <p className="z-10 text-base text-white/80">
                {homepageResearchSection.graphText}
              </p>
            </div>
          </div>
        </div>
        <Metrics />
        <UserDetails />
      </div>
    </Container>
  </div>
);

export default Research;
