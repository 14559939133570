import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';

import { trackEvent } from '@/utils/amplitude-tracking';

import { smoothScrollTo } from '../../../components/common/smooth-scroll';

const CTAElements = ({ link, index, className }) => {
  if (link._type === 'externalLink') {
    return (
      <div key={`cta-link-${index}`}>
        <OutboundLink
          href={link.url}
          onClick={() =>
            trackEvent({
              url: link.url,
            })
          }
          className={className}
        >
          {link.label}
        </OutboundLink>
      </div>
    );
  }

  return (
    <div key={`internal-link-${index}`}>
      <a
        href={`#${link.slug.current}`}
        onClick={(e) => {
          e.preventDefault();
          smoothScrollTo(link.slug.current.replace('#', ''));
        }}
        className={className}
      >
        {link.label}
      </a>
    </div>
  );
};

const GetGenAIStudio = ({ className }) => {
  const query = useStaticQuery(graphql`
    {
      sanityHomePage {
        homepageGetGenAISectionFive {
          title
          subTitle
          description
          ctalinks {
            ... on SanityInternalLink {
              _type
              slug {
                current
              }
              label
              isCta
            }
            ... on SanityExternalLink {
              _type
              label
              url
            }
          }
        }
      }
    }
  `);

  const { homepageGetGenAISectionFive } = query.sanityHomePage;

  return (
    <div
      className={`relative z-10 w-full overflow-hidden bg-smoky-black px-4 py-28 md:rounded-lg ${className}`}
    >
      <div className="text-center text-white">
        <h3 className="mb-4 text-[2.75rem] leading-[3.03125rem] tracking-tight">
          {homepageGetGenAISectionFive.title}
        </h3>
        <p className="text-xl">{homepageGetGenAISectionFive.subTitle}</p>
        <span className="text-xl text-white/80">
          {homepageGetGenAISectionFive.description}
        </span>
        <div className="mt-6 flex w-full flex-wrap justify-center text-center">
          {homepageGetGenAISectionFive.ctalinks?.map((link, index) => (
            <CTAElements
              link={link}
              index={index}
              className="inline-block rounded-lg border-2 border-primary-600 bg-primary-600 px-8 py-4 text-base font-semibold text-white hover:opacity-80"
            />
          ))}
        </div>
      </div>

      {/* Gradients */}
      <div className="absolute -z-10 h-[14.5rem] w-[24.75rem] bg-gradient-hero blur-[7rem] max-md:-right-[50%] max-md:-top-[40%] md:-bottom-[45%] md:w-[44.75rem] lg:-bottom-[25%] lg:-left-[25%]" />
      <div className="absolute -bottom-[45%] -right-[45%] -z-10 hidden h-[14.5rem] w-[44.75rem] bg-gradient-hero blur-[7rem] md:block lg:-bottom-[25%] lg:-right-[25%]" />
    </div>
  );
};

export default GetGenAIStudio;
