'use client';

import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';

const sanityToken = process.env.SANITY_TOKEN;

export const Animation = ({ data, showPlaceholder }) => {
  const [animationJson, setAnimationJson] = useState(null);

  useEffect(() => {
    try {
      fetch(data.animation.asset.url, {
        method: 'GET', // Replace with the desired HTTP method
        headers: {
          Authorization: `Bearer ${sanityToken}}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setAnimationJson(json);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <>
      <Lottie
        options={{
          animationData: animationJson,
          path: data.animation.asset.url,
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            className: !animationJson && showPlaceholder ? '!hidden' : '!block',
          },
        }}
      />
      {!animationJson && showPlaceholder ? (
        <div className="aspect-square h-full w-full" />
      ) : null}
    </>
  );
};

export default Animation;
