import React from 'react';

const indicatorData = {
  preProduction: {
    label: 'Pre-Production',
    points: [{ label: 'Build & Iterate', value: 'build' }],
  },
  production: {
    label: 'Production',
    points: [
      { label: 'Monitor & Debug', value: 'observe' },
      { label: 'Protect', value: 'protect' },
    ],
  },
};

const StepPoints = ({ isVisibleSection, points }) => (
  <ul className="flex items-center gap-4 md:gap-5">
    {points.map(({ value, label }) => (
      <li
        key={value}
        className={`flex items-center gap-1.5 ${
          isVisibleSection === value
            ? 'text-primary-600 [&_span]:bg-primary-600'
            : '[&_span]:bg-smoky-black/40'
        }`}
      >
        <span className="inline-block size-1.5 rounded-[50%]" />
        {label}
      </li>
    ))}
  </ul>
);

const IndicatorLayout = ({ isVisibleSection, type, className = '' }) => {
  const active =
    isVisibleSection === 'build'
      ? type === 'preProduction'
      : type === 'production';

  return (
    <div
      className={`w-full flex-row gap-5 px-6 py-3.5 md:gap-6 ${
        active ? 'flex bg-white' : 'hidden md:flex'
      } ${className}`}
    >
      <p className={`font-bold ${active ? 'text-smoky-black' : ''}`}>
        {indicatorData[type].label}
      </p>

      <StepPoints
        isVisibleSection={isVisibleSection}
        points={indicatorData[type].points}
      />
    </div>
  );
};

const IndicatorBar = ({ isVisibleSection }) => (
  <div className="sticky top-[5.4rem] order-first bg-bluey lg:top-[7rem]">
    <div className="flex flex-col rounded border border-smoky-black/20 bg-white/40 text-base text-smoky-black/40 md:flex-row">
      <IndicatorLayout
        className={`md:w-1/3 md:border-r md:border-smoky-black/20`}
        isVisibleSection={isVisibleSection}
        type="preProduction"
      />
      <IndicatorLayout
        isVisibleSection={isVisibleSection}
        type="production"
        className="md:w-2/3"
      />
    </div>
  </div>
);

export default IndicatorBar;
