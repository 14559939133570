import React, { useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useKeenSlider } from 'keen-slider/react';

import 'keen-slider/keen-slider.min.css';

import QuoteMark from '@/assets/quote-mark.svg';

const Testimonials = ({ testimonials, title, subtitle }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderLoaded, setSetLoaded] = useState(false);
  const [sliderRef, sliderInstanceRef] = useKeenSlider<HTMLDivElement>(
    {
      mode: 'snap',
      loop: true,
      slides: { origin: 'center', perView: 1, spacing: 24 },
      breakpoints: {
        '(min-width: 1024px)': {
          slides: { origin: 'auto', perView: 'auto', spacing: 40 },
        },
      },
      created(slider) {
        setSetLoaded(true);
      },
      updated(slider) {
        setSetLoaded(true);
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
    },
    [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ],
  );

  // Add useEffect to re-render slider on window resize
  useEffect(() => {
    window.addEventListener('resize', () => {
      sliderInstanceRef.current?.update();
    });

    return () => {
      window.removeEventListener('resize', () => {
        sliderInstanceRef.current?.update();
      });
    };
  }, [sliderInstanceRef, sliderLoaded]);

  return (
    <div className="bg-white py-20 md:py-24 lg:py-32">
      <div className="lg-px-20 max-w-5xl px-5 text-left md:mx-auto md:px-10 md:text-center">
        <h2 className="mb-4 text-3xl font-semibold md:text-4xl lg:text-[2.75rem] lg:leading-[3.03125rem]">
          {title}
        </h2>
        <p className="text-xl text-black/80">{subtitle}</p>
      </div>

      <div className="mx-auto max-w-full px-4 sm:px-10 lg:px-0">
        <div
          className="keen-slider mt-9 flex w-full items-stretch pb-1 xl:mt-12"
          ref={sliderRef}
        >
          {testimonials.map(({ review, logo, name, image, title }, index) => (
            <div
              key={index}
              className="keen-slider__slide flex shrink-0 basis-full flex-col items-center rounded-lg border border-[#BEC4E3] bg-white/80 p-6 md:basis-1/2 md:items-start md:p-8 lg:basis-[400px] xl:basis-[450px]"
            >
              <QuoteMark />
              <p className="mt-8 flex-1 text-center text-sm leading-relaxed text-smoky-black md:text-left">
                {review}
              </p>
              <div className="my-2 flex flex-row space-x-2">
                <div className="h-12 w-12 flex-1">
                  <GatsbyImage
                    imgClassName="object-cover object-center"
                    className="h-12 h-full w-12 w-full rounded-full"
                    image={image.asset.gatsbyImageData}
                    alt={name}
                  />
                </div>
                <div>
                  <p className="text-base">{name}</p>
                  <p className="font-outfit max-w-sm text-sm font-light">
                    {title}
                  </p>
                </div>
              </div>
              <GatsbyImage
                imgClassName="!w-auto max-md:!mx-auto"
                className="mt-6 h-5"
                image={logo.asset.gatsbyImageData}
                alt={name}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
