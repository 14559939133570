import React from 'react';
import Container from '@/components/common/v5/container';

import GetGenAIStudio from './get-genAI-studio';
import Resources from './resources';
import Faq from './faq';

const PreFooter = () => (
  <div className="bg-bluey">
    <GetGenAIStudio className="md:hidden" />
    <Container className="flex flex-col gap-12 py-20 md:gap-32 md:py-32">
      <GetGenAIStudio className="hidden md:block" />
      <Resources />
      <Faq />
    </Container>
  </div>
);

export default PreFooter;
