import React from 'react';
import { Link } from 'gatsby';

import { trackEvent } from '@/utils/amplitude-tracking';

type GradientCtaLinkProps = {
  /**
   * CTA label
   */
  label: string;

  /**
   * Flag to check if CTA link is linked to external link or internal
   *
   * @default false
   */
  isExternal?: boolean;

  /**
   * The URL you want to link to
   */
  url?: string;

  className?: string;

  anchorClassName?: string;
};

const GradientCtaLink = ({
  url,
  label,
  isExternal,
  className,
  anchorClassName,
}: GradientCtaLinkProps) => {
  if (isExternal) {
    return (
      <a
        href={url}
        className={`group box-border inline-flex w-fit rounded-lg bg-gradient-to-br from-orangey-yellow via-reddish-magenta to-purply-blue p-0.5 antialiased ${anchorClassName}`}
      >
        <span
          className={`inline-flex rounded-md bg-white px-6 py-2 text-center text-sm font-semibold text-smoky-black transition-colors duration-300 group-hover:bg-milky-white/80 ${className}`}
        >
          {label}
        </span>
      </a>
    );
  }

  return (
    <Link
      onClick={() => trackEvent({ url })}
      to={`${url}`}
      className={`group box-border inline-flex w-fit rounded-lg bg-gradient-to-br from-orangey-yellow via-reddish-magenta to-purply-blue p-0.5 antialiased ${anchorClassName}`}
    >
      <span
        className={`inline-flex rounded-md bg-white px-6 py-2 text-center text-sm font-semibold text-smoky-black transition-colors duration-300 group-hover:bg-milky-white/80 ${className}`}
      >
        {label}
      </span>
    </Link>
  );
};

export default GradientCtaLink;
