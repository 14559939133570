import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';

import ArrowRight from '@/assets/primary-arrow-right.svg';
import { trackEvent } from '@/utils/amplitude-tracking';

const ExploreLinks = ({ link, className }) => {
  if (link._type === 'internalLink') {
    return (
      <Link
        onClick={() => trackEvent(link)}
        to={`/${link.slug.current}`}
        className={className}
      >
        {link.label}
        <ArrowRight />
      </Link>
    );
  }
  if (link._type === 'externalLink') {
    return (
      <OutboundLink
        href={link.url}
        onClick={() => trackEvent(link)}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {link.label}
        <ArrowRight />
      </OutboundLink>
    );
  }
};

const Resources = () => {
  const query = useStaticQuery(graphql`
    {
      sanityHomePage {
        homepageResourcesSectionFive {
          title
          cards {
            title
            description
            date
            ctalinks {
              ... on SanityExternalLink {
                _type
                url
                label
              }
              ... on SanityInternalLink {
                _type
                label
                slug {
                  current
                }
              }
            }
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const { homepageResourcesSectionFive } = query.sanityHomePage;

  return (
    <div className="flex flex-col gap-12">
      <h3 className="text-left text-3xl font-semibold tracking-tight md:text-center md:text-4xl lg:text-[2.75rem] lg:leading-[3.03125rem]">
        {homepageResourcesSectionFive.title}
      </h3>
      <div className="flex flex-wrap justify-center gap-10">
        {homepageResourcesSectionFive.cards.map(
          ({ image, title, date, description, ctalinks }) => (
            <div
              key={title}
              className="group relative flex max-w-[25rem] flex-col rounded-lg border border-white/20 bg-white"
            >
              <div className="max-h-64 w-full">
                <GatsbyImage
                  imgClassName="object-cover w-full h-full rounded-t-lg"
                  image={image.asset.gatsbyImageData}
                  alt={title}
                />
              </div>
              <div className="flex-1 p-8 text-smoky-black">
                <span className="text-sm font-light">{date}</span>
                <h3 className="text-2xl font-medium">{title}</h3>
              </div>
              <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-1 flex-col bg-white p-8 text-smoky-black opacity-0 transition delay-300 ease-in group-hover:opacity-100">
                <span className="text-sm font-light">{date}</span>
                <div className="mt-10 flex-1">
                  <h3 className="text-2xl font-medium">{title}</h3>
                  <p className="my-4 line-clamp-5 text-sm md:text-base">
                    {description}
                  </p>
                </div>
                {ctalinks.map((link) => (
                  <ExploreLinks
                    link={link}
                    className="flex items-center gap-3 text-base font-medium text-primary-600 hover:opacity-80"
                  />
                ))}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default Resources;
