import React from 'react';

import Container from '@/components/common/v5/container';

import ProcessBuild from '@/assets/process-build2.svg';
import ProcessObserve from '@/assets/process-observe2.svg';
import ProcessProtect from '@/assets/process-protect2.svg';
import ProcessBackground from '@/assets/process-background.svg';

const Column = ({ label, contents, className = '' }) => (
  <div
    className={`w-full rounded-lg border border-smoky-black/20 ${className}`}
  >
    <div className="w-full border-b border-smoky-black/20 p-6 font-bold">
      {label}
    </div>
    <div className="flex flex-col md:flex-row">
      {contents.map(
        ({ title, description, className = '', bgImage, image }) => (
          <div
            className={`flex shrink-0 flex-col gap-10 p-6 ${
              contents.length === 1 ? 'w-full' : 'w-full md:w-1/2'
            } ${className}`}
            key={title}
          >
            <h5 className="text-2xl leading-9 text-black">{title}</h5>
            <div className="relative w-full">
              <div className="max-h-[13.25rem]">{bgImage}</div>
              <div className="delay-50 absolute bottom-0 left-0 right-0 top-0 m-auto h-fit w-fit transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110">
                {image}
              </div>
            </div>
            <p className="text-black/80">{description}</p>
          </div>
        ),
      )}
    </div>
  </div>
);

// TODO: Remove dummy data and add query
const EndToEndPlatform = () => (
  <div className="bg-white">
    <Container className="flex flex-col gap-12 py-12 lg:py-32">
      <h3 className="text-left text-3xl font-semibold tracking-tight md:text-center lg:mx-44 lg:text-[2.75rem] lg:leading-[3.03125rem]">
        An end-to-end platform for GenAI evaluation, experimentation,
        observability, and protection.
      </h3>

      <div className="flex flex-col text-base md:flex-row">
        <Column
          label="Pre-Production"
          contents={[
            {
              title: 'Build & Iterate',
              description:
                'Stop experimenting in spreadsheets and notebooks. Use Evaluate’s powerful insights to build GenAI systems that just work.',
              bgImage: <ProcessBackground className="w-full object-cover" />,
              image: <ProcessBuild />,
            },
          ]}
          className="max-md:border-b-0 md:w-1/3"
        />

        <Column
          label="Production"
          contents={[
            {
              title: 'Monitor & Debug',
              description:
                'Observe proactively monitors production and surfaces notifications to precisely identify and debug gaps.',
              className: 'max-md:border-b md:border-r border-smoky-black/20',
              bgImage: <ProcessBackground className="w-full object-cover" />,
              image: <ProcessObserve />,
            },
            {
              title: 'Protect',
              description:
                'Protect intercepts prompts and outputs to safeguard applications and end-users.',
              bgImage: <ProcessBackground className="w-full object-cover" />,
              image: <ProcessProtect />,
            },
          ]}
          className="md:w-2/3 md:border-l-0"
        />
      </div>
    </Container>
  </div>
);

export default EndToEndPlatform;
