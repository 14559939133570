import React, { useState, useRef, useEffect } from 'react';
import { PortableText } from '@portabletext/react';

import { blogpostPortableText } from '@/templates/blog-post';

import OpenClose from './open-close';

const AccordionItem = ({ title, content }) => {
  const [isActive, setActive] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', function () {
      if (ref.current) {
        setHeight(ref.current.offsetHeight);
      }
    });
    return () => {
      window.removeEventListener('resize', function () {
        if (ref.current) {
          setHeight(ref.current.offsetHeight);
        }
      });
    };
  });

  return (
    <div className="border-b border-[#6a6a6a]/20 py-4 pr-6 lg:py-6 lg:pr-10">
      <div
        className="relative cursor-pointer pr-6 text-base sm:text-lg"
        onClick={() => setActive(!isActive)}
      >
        <div className={`svg-wrap absolute -right-2 top-1.5 h-4 w-4`}>
          <OpenClose isActive={isActive} color={`text-accent-green`} />
        </div>
        {title}
      </div>
      <div
        className={`transition-height overflow-hidden duration-500 ease-in-out`}
        style={{
          height: `${isActive ? height : 0}px`,
        }}
      >
        <div ref={ref}>
          <div className="pt-4 text-grey-50/60">
            <PortableText
              value={content}
              components={blogpostPortableText as any}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
