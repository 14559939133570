import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/common/v5/layout';
import Hero from '@/components/homepage/v5/hero';
import { Logos } from '@/components/homepage/v4/logos';
import BuiltFor from '@/components/homepage/v5/built-for';
import Meet from '@/components/homepage/v5/meet';
import Testimonials from '@/components/homepage/v5/testimonials';
import SEO from '@/components/seo';
import PreFooter from '@/components/homepage/v5/pre-footer';
import CollaborativePlatform from '@/components/homepage/v5/collaborative-platform';
import EndToEndPlatform from '@/components/homepage/v5/end-to-end-platform';
import IntegrateStack from '@/components/homepage/v5/integrate-stack';
import Research from '@/components/homepage/v5/research';

const sectionTitlePortableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="bg-gradient-to-br from-y via-rm to-pb bg-clip-text text-transparent antialiased">
        {children}
      </span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const whySectionTitlePortableText = {
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children, value }) => (
      <span className="bg-gradient-to-br from-pb via-pb to-pb bg-clip-text text-transparent antialiased">
        {children}
      </span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a href={value.href} rel={rel}>
          {children}
        </a>
      );
    },
  },
};

const Index = ({ data }) => {
  const {
    homepageHeroSectionFive,
    testimonialsSectionFive,
    homepageBuildSystemSectionFive,
    homepageCollaborativeSectionFive,
    homepageBuiltForSectionFive,
    homepageResearchSection,
  } = data.sanityHomePage;

  return (
    <Layout>
      <div className="overflow-x-hidden">
        <Hero hero={homepageHeroSectionFive} />

        <div>
          <p className="mb-5 px-4 text-center text-base font-medium text-white opacity-80">
            {homepageHeroSectionFive.customersTitleV2}
          </p>
          <div className="border-y border-solid border-y-[#2A2A2A] py-6">
            <Logos logos={homepageHeroSectionFive.customers} />
          </div>
        </div>
      </div>
      <Research homepageResearchSection={homepageResearchSection} />
      <EndToEndPlatform />
      <Meet data={homepageBuildSystemSectionFive} />
      <Testimonials
        testimonials={testimonialsSectionFive.testimonials}
        title={testimonialsSectionFive.title}
        subtitle={testimonialsSectionFive.subtitle}
      />
      <CollaborativePlatform
        title={homepageCollaborativeSectionFive._rawTitle}
        description={homepageCollaborativeSectionFive.description}
        cards={homepageCollaborativeSectionFive.cards}
      />
      <IntegrateStack />
      <BuiltFor data={homepageBuiltForSectionFive} />
      <PreFooter />
    </Layout>
  );
};

export default Index;

// TODO: Update query
export const query = graphql`
  query HomePageQuery {
    cv: file(relativePath: { eq: "computer-vision.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          width: 400
          height: 276
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    genai: file(relativePath: { eq: "gen-ai.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          width: 400
          height: 276
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    nlp: file(relativePath: { eq: "nlp.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          width: 400
          height: 276
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    sanityHomePage {
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }

      homepageHeroSectionFive {
        _rawTitleV2(resolveReferences: { maxDepth: 10 })
        _rawSubTitleV2(resolveReferences: { maxDepth: 10 })

        heroAnimation {
          asset {
            url
          }
        }

        customersTitleV2

        customers {
          asset {
            url
          }
        }
        ctaLinksV2 {
          ... on SanityExternalLink {
            _type
            label
            url
          }
          ... on SanityInternalLink {
            isCta
            label
            slug {
              current
            }
            _type
          }
        }
      }

      homepageResearchSection {
        topTitle
        _rawTitle(resolveReferences: { maxDepth: 10 })
        _rawSubTitle(resolveReferences: { maxDepth: 10 })
        _rawTextAboveLink(resolveReferences: { maxDepth: 10 })
        researchPageLink {
          isCta
          label
          slug {
            current
          }
        }
        graphTitle
        graphSubtitle
        graphText
      }

      homepageBuildSystemSectionFive {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        meetSections {
          links {
            ... on SanityExternalLink {
              _type
              label
              url
            }
            ... on SanityInternalLink {
              isCta
              label
              slug {
                current
              }
              _type
            }
          }
          icon {
            asset {
              url
            }
          }
          subTitle
          text
          title
          animation {
            asset {
              url
            }
          }
        }
      }

      homepageBuiltForSectionFive {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        title {
          children {
            text
          }
        }
        ctalinks {
          ... on SanityExternalLink {
            _type
            label
            url
          }
          ... on SanityInternalLink {
            isCta
            label
            slug {
              current
            }
            _type
          }
        }
        builtForItems {
          title
          description
          image {
            asset {
              url
            }
          }
          links {
            ... on SanityExternalLink {
              _key
              _type
              label
              url
            }
            ... on SanityInternalLink {
              _key
              _type
              isCta
              label
              slug {
                current
              }
            }
          }
        }
      }

      testimonialsSectionFive {
        title
        subtitle
        testimonials {
          name
          title
          review
          logo {
            asset {
              gatsbyImageData
            }
          }
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }

      homepageCollaborativeSectionFive {
        _rawTitle(resolveReferences: { maxDepth: 10 })
        description
        cards {
          title
          description
          image {
            asset {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const {
    seo: { metaTitle, metaDescription, image },
  } = data.sanityHomePage;

  return (
    <SEO
      title={metaTitle}
      description={metaDescription}
      image={image ? image.asset.localFile.publicURL : null}
      imageWidth={
        image ? image.asset.localFile.childImageSharp.fixed.width : null
      }
      imageHeight={
        image ? image.asset.localFile.childImageSharp.fixed.height : null
      }
    />
  );
};
