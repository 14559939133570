import React from 'react';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';

import ArrowRight from '@/assets/primary-arrow-right.svg';
import Fade from '@/components/common/fade';
import { trackEvent } from '@/utils/amplitude-tracking';

const CTALinks = ({ link, className }) => {
  if (link._type === 'externalLink') {
    return (
      <OutboundLink
        href={link.url}
        onClick={() =>
          trackEvent({
            url: link.url,
          })
        }
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {link.label}
        <ArrowRight />
      </OutboundLink>
    );
  }

  return (
    <a
      href={link.slug.current}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      {link.label}
      <ArrowRight />
    </a>
  );
};

const StepDetails = ({ data }) => (
  <Fade>
    <div className="flex flex-col gap-6 md:gap-12">
      <div className="flex flex-col gap-4 md:gap-6">
        <h3 className="text-2xl leading-7 -tracking-[0.01em] text-smoky-black md:text-[2.5rem] md:leading-[3rem]">
          {data.subTitle}
        </h3>
        <p className="text-base text-smoky-black/80 xl:text-xl">{data.text}</p>
      </div>
      <div className="order-first flex flex-row items-center gap-3">
        <div className="size-6">
          <img src={data.icon.asset.url} />
        </div>
        {data.title && (
          <h5 className="text-2xl font-medium capitalize leading-9 text-black">
            {data.title}
          </h5>
        )}
      </div>
      {data.links &&
        data.links.map((link, index) => (
          <CTALinks
            link={link}
            key={`cta-links-${index}`}
            className="flex items-center gap-3 text-base font-medium text-primary-600 hover:opacity-80"
          />
        ))}
    </div>
  </Fade>
);

export default StepDetails;
