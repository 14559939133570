import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const mainCardSmallBoxData = [
  {
    label: 'Evaluate',
    icon: (
      <StaticImage
        alt="Evaluate"
        src="../../../../assets/evaluate-icon.svg"
        className="object-contain"
      />
    ),
  },
  {
    label: 'Observe',
    icon: (
      <StaticImage
        alt="Observe"
        src="../../../../assets/observe-icon.svg"
        className="object-contain"
      />
    ),
  },
  {
    label: 'Protect',
    icon: (
      <StaticImage
        alt="Protect"
        src="../../../../assets/protect-icon.svg"
        className="object-contain"
      />
    ),
  },
];

const IconWithLabel = ({ label, icon, shouldAddSymbol = false }) => (
  <div className="flex items-center gap-2">
    <div className="h-6 w-6">{icon}</div>
    {shouldAddSymbol ? <p>{label}&#174;</p> : <p>{label}</p>}
  </div>
);

export const dummyData = {
  title: 'Integrate with your whole GenAI stack',
  description:
    'Galileo is designed to easily work with any model, any framework, any stack.',
  mainCardSmallBoxData: mainCardSmallBoxData.map((data) => (
    <IconWithLabel key={data.label} {...data} shouldAddSymbol />
  )),
  mainCardLargeBoxData: (
    <IconWithLabel
      key="Luna: Evaluation Foundation Model Layer"
      label="Luna: Evaluation Foundation Model Layer"
      icon={
        <StaticImage
          alt="Luna: Evaluation Foundation Model Layer"
          src="../../../../assets/evaluate-icon.svg"
          className="hidden object-contain md:block"
        />
      }
    />
  ),
  upperCardsData: [
    {
      title: 'Applications',
      content: (
        <div className="flex flex-wrap items-center gap-4">
          <div className="flex items-center gap-2">
            <StaticImage
              alt="Content Generation"
              src="../../../../assets/content-generation.svg"
            />
            <p>Content Generation</p>
          </div>
          <div className="flex items-center gap-2">
            <StaticImage
              alt="Semantic Search"
              src="../../../../assets/semantic-search.svg"
            />
            <p>Semantic Search</p>
          </div>
          <div className="flex items-center gap-2">
            <StaticImage alt="Agents" src="../../../../assets/agents.svg" />
            <p>Agents</p>
          </div>
          <div className="flex items-center gap-2">
            <StaticImage alt="Chatbots" src="../../../../assets/chatbots.svg" />
            <p>Chatbots</p>
          </div>
        </div>
      ),
      isAtTop: true,
    },
    {
      title: 'Orchestration layer',
      content: (
        <div className="flex flex-wrap items-center gap-4">
          <div className="flex items-center gap-2">
            <StaticImage
              alt="LlamaIndex"
              src="../../../../assets/llamaindex.png"
            />
            <p>LlamaIndex</p>
          </div>
          <div className="flex items-center gap-2">
            <StaticImage
              alt="Langchain"
              src="../../../../assets/langchain.png"
            />
            <p>Langchain</p>
          </div>
        </div>
      ),
      isAtTop: true,
    },
  ],
  lowerCardsData: [
    {
      title: 'Input',
      content: (
        <ul className="flex flex-col gap-3">
          <li className="flex items-center gap-2">
            <StaticImage
              alt="check"
              src="../../../../assets/check-white.svg"
              className="h-6 w-6"
              imgClassName="object-contain"
            />
            <p>Prompts</p>
          </li>
          <li className="flex items-center gap-2">
            <StaticImage
              alt="check"
              src="../../../../assets/check-white.svg"
              className="h-6 w-6"
              imgClassName="object-contain"
            />
            <p>Training Data</p>
          </li>
          <li className="flex items-center gap-2">
            <StaticImage
              alt="check"
              src="../../../../assets/check-white.svg"
              className="h-6 w-6"
              imgClassName="object-contain"
            />
            <p>Context Data</p>
          </li>
        </ul>
      ),
    },
    {
      title: 'Model',
      content: (
        <div className="flex flex-wrap gap-6">
          <StaticImage
            alt="model logos"
            src="../../../../assets/model-1.svg"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="model logos"
            src="../../../../assets/model-2.svg"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="model logos"
            src="../../../../assets/model-3.svg"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="model logos"
            src="../../../../assets/model-4.svg"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="model logos"
            src="../../../../assets/model-5.svg"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="model logos"
            src="../../../../assets/model-6.svg"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="model logos"
            src="../../../../assets/model-7.png"
            className="h-5 w-auto"
          />
        </div>
      ),
    },
    {
      title: 'RAG Vector Database',
      content: (
        <div className="flex flex-wrap gap-6">
          <StaticImage
            alt="RAG Vector Database logos"
            src="../../../../assets/rag-1.png"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="RAG Vector Database logos"
            src="../../../../assets/rag-2.svg"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="RAG Vector Database logos"
            src="../../../../assets/rag-3.svg"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="RAG Vector Database logos"
            src="../../../../assets/rag-4.svg"
            className="h-5 w-auto"
          />
        </div>
      ),
    },
    {
      title: 'Cloud Provider',
      content: (
        <div className="flex flex-wrap gap-6">
          <StaticImage
            alt="cp logos"
            src="../../../../assets/cp-1.svg"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="cp logos"
            src="../../../../assets/cp-2.svg"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="cp logos"
            src="../../../../assets/cp-3.svg"
            className="h-5 w-auto"
          />
          <StaticImage
            alt="cp logos"
            src="../../../../assets/cp-4.svg"
            className="h-5 w-auto"
          />
        </div>
      ),
    },
  ],
  ctalinks: [
    {
      _type: 'externalLink',
      label: 'Explore Our Integrations',
      slug: { current: '#' },
    },
  ],
};
