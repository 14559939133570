import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Accordion from '@/components/common/v5/accordion/accordion';

const Faq = () => {
  const query = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        faqItems {
          question
          _rawAnswer(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  `);

  const { faqItems } = query.sanitySiteSettings;

  return (
    <div className="flex flex-col items-start justify-between gap-8 md:flex-row">
      <h3 className="w-[33%] text-[2.75rem] leading-[3.03125rem] tracking-tight">
        FAQ
      </h3>
      <Accordion data={faqItems} />
    </div>
  );
};

export default Faq;
