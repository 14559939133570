import React from 'react';
import { PortableText } from '@portabletext/react';

import Container from '@/components/common/v5/container';
import { titlePortableText } from '@/components/homepage/v5/hero';

import StickyBar from './stickyBar';

// TODO: Update query
const Meet = ({ data }) => (
  <div className="bg-bluey">
    <Container className="flex flex-col gap-12 py-12 md:gap-24 lg:py-32">
      <h3 className="text-left text-3xl font-semibold tracking-tight md:text-center lg:text-[2.75rem] lg:leading-[3.03125rem]">
        <PortableText
          value={data._rawTitle}
          components={titlePortableText as any}
        />
      </h3>

      <StickyBar meetSections={data.meetSections} />
    </Container>
  </div>
);

export default Meet;
