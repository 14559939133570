import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Container from '@/components/common/v5/container';
import GradientCtaLink from '@/components/common/v5/gradient-cta-link';

import { dummyData } from './data';

const DataCards = ({ cardsData }) => (
  <div className="flex w-full flex-col md:flex-row md:gap-5 lg:gap-10">
    {cardsData.map(({ title, content, isAtTop = false }) => (
      <div className="flex w-full flex-col items-center">
        {!isAtTop && <div className="h-[3.125rem] w-6 bg-card-pipe-reverse" />}
        <div className="flex w-full flex-1 flex-col rounded-xl border border-white/20">
          <div className="rounded-xl bg-[#0f0f0f] px-6 py-4 text-center md:text-left">
            {title}
          </div>
          <div className="hidden flex-1 rounded-b-xl border-t border-white/20 bg-smoky-black px-6 py-4 text-base text-white/60 md:block">
            {content}
          </div>
        </div>
        {isAtTop && <div className="h-[3.125rem] w-6 bg-card-pipe" />}
      </div>
    ))}
  </div>
);

const ContentBoxLayout = ({ children }) => (
  <div className="flex flex-1 items-center justify-center rounded-lg border border-white/20 bg-smoky-black px-6 py-8 text-center text-xl font-semibold">
    {children}
  </div>
);

const MainCard = ({ logoLight }) => (
  <div className="w-full rounded-xl bg-integrate-stack">
    <div className="relative m-0.5 flex w-auto flex-col gap-8 overflow-hidden rounded-xl bg-smoky-black p-6 md:flex-row">
      <div className="z-10 flex w-full flex-col items-center justify-between gap-8 md:w-1/4 md:items-start">
        <div className="h-[3.125rem] md:h-[4.685rem]">
          <img
            src={logoLight.asset.gatsbyImageData.images.fallback.src}
            className="h-full"
          />
        </div>
        <div className="flex items-center gap-9 text-center md:items-start md:text-left">
          <div className="flex flex-col items-center gap-2 text-center md:items-start md:text-left">
            <StaticImage
              src="../../../../assets/cloud.svg"
              alt="cloud"
              className="h-auto w-auto"
            />
            <p>Galileo SaaS</p>
          </div>
          <div className="flex flex-col items-center gap-2 text-center md:items-start md:text-left">
            <StaticImage
              src="../../../../assets/server.svg"
              alt="server"
              className="h-auto w-auto"
            />
            <p>Galileo On-Premise</p>
          </div>
        </div>
      </div>
      <div className="z-10 flex w-full flex-col gap-2 md:w-3/4">
        <div className="flex flex-col flex-wrap gap-2 md:flex-row">
          {dummyData.mainCardSmallBoxData.map((content) => (
            <ContentBoxLayout>{content}</ContentBoxLayout>
          ))}
        </div>
        <ContentBoxLayout>{dummyData.mainCardLargeBoxData}</ContentBoxLayout>
      </div>

      {/* Gradients */}
      <div className="absolute -left-[30%] -top-[25%] h-[8rem] w-[22.5rem] bg-gradient-hero blur-[4rem] lg:-left-[15%] lg:-top-[60%]" />
      <div className="absolute -right-[15%] -top-[60%] hidden h-[8rem] w-[22.5rem] bg-gradient-hero blur-[4rem] md:block" />
      <div className="absolute left-[35%] top-[80%] h-[53rem] w-[41rem] bg-gradient-hero blur-[6.5rem] md:left-[25%] md:top-[100%]" />
    </div>
  </div>
);

// TODO: Remove dummy data and add query
const IntegrateStack = () => {
  const query = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        logoLight {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const { logoLight } = query.sanitySiteSettings;

  return (
    <div className="overflow-hidden">
      <Container className="relative z-10 flex flex-col items-center gap-12 py-20 text-white md:py-32">
        <div className="absolute -bottom-[20%] left-[calc(50%-460px)] -z-10 hidden h-[18.7rem] w-[57.5rem] bg-gradient-hero blur-[9rem] md:block" />

        <div className="max-w-5xl text-left md:mx-auto md:px-10 md:text-center">
          <h2 className="mb-4 text-3xl font-semibold md:text-4xl lg:text-[2.75rem] lg:leading-[3.03125rem]">
            {dummyData.title}
          </h2>
          <p className="text-xl text-white/80 lg:px-20">
            {dummyData.description}
          </p>
        </div>

        <div className="flex w-full flex-col">
          <DataCards cardsData={dummyData.upperCardsData} />
          <MainCard logoLight={logoLight} />
          <DataCards cardsData={dummyData.lowerCardsData} />
        </div>

        {/* Currently not needed */}
        {/* <div className="mt-5 md:mt-10">
          {dummyData.ctalinks.map(({ _type, label, slug }) => (
            <GradientCtaLink
              url={slug.current}
              label={label}
              isExternal={_type === 'externalLink'}
              className="!bg-smoky-black text-white"
            />
          ))}
        </div> */}
      </Container>
    </div>
  );
};

export default IntegrateStack;
