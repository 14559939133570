import React from 'react';

import Container from '@/components/common/v5/container';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PortableText } from '@portabletext/react';

const CollaborativePlatform = ({ cards, title, description }) => (
  <div className="bg-bluey">
    <Container className="flex flex-col gap-12 py-20 md:py-32">
      <div className="max-w-5xl text-left md:mx-auto md:px-10 md:text-center">
        <h2 className="mb-4 text-3xl font-semibold md:text-4xl lg:text-[2.75rem] lg:leading-[3.03125rem]">
          <PortableText value={title} />
        </h2>
        <p className="text-xl text-black/80 lg:px-20">{description}</p>
      </div>
      <div className="flex flex-wrap justify-center gap-10">
        {cards.map(({ image, title, description }) => (
          <div
            key={title}
            className="group relative flex max-w-[25rem] flex-col rounded-lg border border-black/20"
          >
            <div className="flex h-72 w-full items-center justify-center px-4 py-3.5">
              <GatsbyImage
                imgClassName="object-contain w-auto h-full"
                image={image.asset.gatsbyImageData}
                alt={title}
              />
            </div>
            <div className="flex-1 rounded-b-lg bg-white px-4 py-6 text-center">
              <h3 className="text-2xl font-medium text-smoky-black">{title}</h3>
              <p className="mt-4 text-base text-smoky-black/80 md:text-base">
                {description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Container>
  </div>
);

export default CollaborativePlatform;
